@import url(https://use.typekit.net/air4dmd.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  font-family: minion-3-display, serif;
  font-weight: 500;
  font-style: normal;
}

body {
  background: #251b3d;
}

img {
  max-width: 100%;
}

.App {
  text-align: center;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.video-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.band-title {
  text-transform: uppercase;
  margin: 0;
  font-size: calc(7.5 * 1.2vmin);
  line-height: 1.3;
  letter-spacing: 7px;
}

.album-title {
  text-transform: uppercase;
  color: #fbd505;
  font-size: calc(3 * 1.2vmin);
  line-height: 0.8;
  margin: 0;
  letter-spacing: 4px;
}

.spring {
  display: block;
  font-size: calc(10 * 1.2vmin);
}

.header {
  color: #fbd505;
  width: 100%;
  padding: 24px;
}

.nav {
  width: 100%;
  padding: 2em;
}

.nav-item {
  padding: 1em;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fbd505;
  font-size: calc(10px + 1.5vmin);
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.icons {
  align-items: center;
  color: white;
  justify-content: center;
  -webkit-transform: translateY(-2%);
          transform: translateY(-2%);
  display: inline;
}

.icon {
  margin: 16px;
  width: 48px;
  height: 48px;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.icon:hover,
.icon:focus {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.icon:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.icon-instagram {
  width: 46px;
  height: 46px;
}

.icon-youtube {
  width: 52px;
}

.icon-close-container {
  position: absolute;
  bottom: 12px;
}

.icon-play {
  width: 96px;
  height: 96px;
}
@media screen and (min-width: 596px) {
  .icons {
    display: flex;
    width: auto;
  }

  .icon {
    width: 48px;
    height: 48px;
  }

  .icon-instagram {
    width: 50px;
    height: 50px;
  }

  .icon-youtube {
    width: 60px;
  }

  .icon-play {
    width: 96px;
    height: 96px;
  }
}

